import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView")
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView")
  },
  {
    path: "/timeline",
    name: "timeline",
    component: () => import("@/views/TimelineView")
  },
  {
    path: "/photo-wall",
    name: "photo-wall",
    component: () => import("@/views/PhotoWallView")
  },
  {
    path: "/message-board",
    name: "message-board",
    component: () => import("@/views/MessageBoardView")
  },
  {
    path: "/go-together",
    name: "go-together",
    component: () => import("@/views/GoTogetherView")
  }
]

const router = new VueRouter({
  routes
})

export default router
