<template>
  <div id="app">
    <router-view />
    <van-tabbar route v-if="$route.path !== '/login' && userToken">
      <van-tabbar-item to="/timeline" icon="todo-list-o">时间线</van-tabbar-item>
      <van-tabbar-item to="/photo-wall" icon="photo-o">照片墙</van-tabbar-item>
      <van-tabbar-item to="/" name="home-o" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="/message-board" icon="friends-o">留言板</van-tabbar-item>
      <van-tabbar-item to="/go-together" icon="location-o">一起去</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from "vuex"
  import {startSakura} from "./assets/js/sakura"
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapState(["userToken"])
    },
    mounted() {
      startSakura()
    }
  }
</script>
<style lang="scss" scoped>
  #app {
    background: url("http://love-log.s3-accelerate.cn-north-1.jdcloud-oss.com/mobile_bg.png");
    background-size: 107px 200px;
    height: 100vh;
  }
</style>
