import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Vant from "vant"
import "vant/lib/index.css"
import axios from "axios"
import AWS from "aws-sdk"

// Import the AWS SDK
let oss = new AWS.S3({apiVersion: "2006-03-01"})
oss.endpoint = "https://s3.cn-north-1.jdcloud-oss.com"
oss.config.update({
  accessKeyId: "JDC_54E58237E0394C7B63B38F24F619",
  secretAccessKey: "EE6AFADA9B77FEB0720EE9CE0B2A8722",
  s3ForcePathStyle: true,
  signatureVersion: "v4"
})

Vue.prototype.$oss = oss

axios.interceptors.request.use((config) => {
  // 添加请求头示例(自行定义)
  config.headers["Content-Type"] = "multipart/form-data"
  config.headers["userToken"] = store.state.userToken
  // 必须返回config
  return config
})
axios.interceptors.response.use(
  function (response) {
    if (response.data.code === 401) {
      router.push("/login")
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
Vue.use(Vant)
Vue.config.productionTip = false
Vue.prototype.$http = axios
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
