import Vue from "vue"
import Vuex from "vuex"
import CreatePersistedstate from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    CreatePersistedstate({
      storage: window.sessionStorage
    })
  ],
  state: {
    user: null,
    userToken: null
  },
  getters: {},
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setUserToken(state, userToken) {
      state.userToken = userToken
    }
  },
  actions: {},
  modules: {}
})
